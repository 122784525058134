@font-face {
    font-family: 'NotoSans-Regular';
    src: url('../../assets/fonts/NotoSans-Regular.ttf');
}
@font-face {
    font-family: 'NotoSans-Bold';
    src: url('../../assets/fonts/NotoSans-Bold.ttf');
}
@font-face {
    font-family: 'NotoSans-SemiBold';
    src: url('../../assets/fonts/NotoSans-SemiBold.ttf');
}

.Main {
    background-color: #FFF;
    padding: 16px 16px;
}

.Property-Details-Section {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.Text-Button-Text {
    font-family: 'NotoSans-SemiBold';
    font-size: 16px;
    color: #0D7EE5;
}

.Text-Button {
    background-color: transparent;
    border: 0;
    padding: 0;
}

.Property-Text {
    font-family: 'NotoSans-Regular';
    font-size: 16px;
}

hr {
    border-color: rgb(213, 213, 213);
    border-top: 1px;
    margin: 16px 0;
}

.Manager-Details {
    display: flex;
}

.Manager-Label {
    font-family: 'NotoSans-Regular';
    font-size: 12px;
    color: #747474;
    display: flex;
}

.Manager-Name {
    font-family: 'NotoSans-Regular';
    font-size: 14px;
    color: #272727;
    margin-top: 4px;
    margin-right: 4px;
}

.Manager-Contact {
    font-family: 'NotoSans-SemiBold';
    font-size: 14px;
    color: #F3954F;
    margin-top: 4px;
    margin-right: 4px;
}