@font-face {
    font-family: 'NotoSans-Regular';
    src: url('../../assets/fonts/NotoSans-Regular.ttf');
}
@font-face {
    font-family: 'NotoSans-Bold';
    src: url('../../assets/fonts/NotoSans-Bold.ttf');
}
@font-face {
    font-family: 'NotoSans-SemiBold';
    src: url('../../assets/fonts/NotoSans-SemiBold.ttf');
}

.Main-Container {
    background-color: #FFF;
    border-radius: 12px;
    padding: 16px 16px;
}

.Sub-Heading-Text {
    font-family: 'NotoSans-Regular';
    font-size: 12px;
    color: #828282;
}

.Room-Category-Text {
    font-family: 'NotoSans-Bold';
    font-size: 18px;
    color: #272727;
}

.Text-Button-Container {
    display: flex;
    justify-content: space-between;
}

.Text-Button {
    background-color: transparent;
    border: 0;
    padding: 0;
}

.Text-Button-Text {
    font-family: 'NotoSans-SemiBold';
    font-size: 14px;
    color: #0D7EE5;
}

.Trailing-Text-Container {
    display: flex;
    justify-content: space-between;
}

.Trailing-Text {
    font-family: 'NotoSans-Regular';
    font-size: 14px;
    color: #272727;
}