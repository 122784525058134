@font-face {
    font-family: 'NotoSans-Regular';
    src: url('../../assets/fonts/NotoSans-Regular.ttf');
}
@font-face {
    font-family: 'NotoSans-Bold';
    src: url('../../assets/fonts/NotoSans-Bold.ttf');
}
@font-face {
    font-family: 'NotoSans-SemiBold';
    src: url('../../assets/fonts/NotoSans-SemiBold.ttf');
}

.Modal-Container {
    background-color: #FFF;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    max-width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.Modal-Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.Close-Button{
    right: 16px;
    top: 16px;
    display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.Modal-Close-Button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  border-spacing: 0px;
}

.Modal-Header{
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-inline: 16px;
}

.Header-Text {
    font-family: 'NotoSans-SemiBold';
    font-size: 18px;
}

.Amenities-List {
  padding-inline: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.Amenity-Item {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px 12px;
  font-family: 'NotoSans-Regular';
  font-size: 14px;
}
