@font-face {
    font-family: 'NotoSans-Regular';
    src: url('../../assets/fonts/NotoSans-Regular.ttf');
}
@font-face {
    font-family: 'NotoSans-Bold';
    src: url('../../assets/fonts/NotoSans-Bold.ttf');
}
@font-face {
    font-family: 'NotoSans-SemiBold';
    src: url('../../assets/fonts/NotoSans-SemiBold.ttf');
}

.Background {
    background-color: #3d4956;
}

.App {
  /* text-align: center; */
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
  background-color: #F0F4F9;
  min-height: 100vh;
  box-shadow: 0px 0px 20px rgba(83, 71, 71, 0.05);
}

@media (min-width: 768px) {
  .App {
    width: 60%;
  }
}

.Header {
    background-color: #FFF;
    height: 8vh;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1000;
}

.Header-Text {
    font-family: 'NotoSans-Bold';
    font-size: 18px;
}

.Body {
    width: 100%;
    overflow-y: auto;
}

.Address-Container {
    display: flex;
    justify-content: space-between;
    padding-inline: 16px;
}

.Address-Details {
    display: flex;
    align-items: center;
    flex: 5;
    margin-right: 8px;
}

.Location-Icon {
    min-width: 32px;
    height: 32px;
    display: flex;
    margin-right: 8px;
}

.View-On-Map {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-family: 'NotoSans-SemiBold';
    font-size: 14px;
    color: #F3954F;
    flex: 1,
}

.Address-Text {
    font-family: 'NotoSans-Regular';
    font-size: 14px;
    display: flex;
    text-align: left;
}

.Room-Card-Section {
    padding-left: 16px;
    padding-right: 16px;
    text-align: start;
}

.Room-Details-Text {
    font-family: 'NotoSans-Regular';
    font-size: 12px;
    color: #4F4F4F;
}

.Services-Section {
    background-color: #FFF;
    padding: 16px;
    text-align: start;
}

.Services-Button-Section {
    display: flex;
    justify-content: space-between;
}

.Text-Button {
    background-color: transparent;
    border: 0;
    padding: 0;
}

.Services-Text {
    font-family: 'NotoSans-SemiBold';
    font-size: 14px;
    color: #272727;
}

.View-List-Button {
    font-family: 'NotoSans-SemiBold';
    font-size: 14px;
    color: #F3954F;
}

.Bottom-Container {
    display: block;
}

.Footer-Text {
    font-family: 'NotoSans-Regular';
    font-size: 16px;
    color: #747474;
}